export const environment = {
  production: false,
  apiUrlConfigs: 'https://iothub-test.syensqo.com/api/v1/configuration/',
  apiUrlCommands: 'https://iothub-test.syensqo.com/api/v1/command/',
  firebaseConfig: {
    apiKey: 'AIzaSyA2PLMpXN-wdLinZfDKnvTRFLmHQX06fUo',
    authDomain: 'gcp-sqo-iothub-t.firebaseapp.com',
    databaseURL: 'https://gcp-sqo-iothub-t.firebaseio.com',
    projectId: 'gcp-sqo-iothub-t',
    storageBucket: 'gcp-sqo-iothub-t.firebasestorage.app',
    messagingSenderId: '894155051311',
    appId: '1:894155051311:web:f9c494e270d59ab46e91da',
  },
  googleSheetBaseUrl: 'https://docs.google.com/spreadsheets/d/',
  supportGoogleSheetUrl:
    'https://docs.google.com/spreadsheets/d/11pN9PYlnGZssPa3mJf9vFpoRcuWNfT8JQURaZ-JsBv8/edit#gid=0',
  gsExporterServiceAccount: 'gcp-sqo-iothub-t@appspot.gserviceaccount.com',
  openStreetMap: {
    defaultMapCenter: {
      lat: 50.901072,
      lng: 4.400331,
    },
    layerTilesUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  serviceAccountGoogleSheet: 'gcp-sqo-iothub-t@appspot.gserviceaccount.com',
  stage: 't',
};
